<template>
  <div ref="gridLayOutDiv">

    <avue-crud v-if="isShow"
               :option="option"
               :data="tableData"
               size="mini"
               ref="grid"
               @on-load="onLoad"
    >
      <template slot-scope="scope" slot="menuRight">
        <el-button
          icon="el-icon-plus"
          size="mini"
          style="margin-top: 10px"
          @click="addRow(scope.row)"
        >新增
        </el-button>
        <el-button
          size="mini"
          icon="el-icon-delete"

          @click="removeFile(scope.row)"
        >删 除
        </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button
          size="small"
          icon="el-icon-delete"
          type="text"

          @click="uploadClick(scope.row)"
        >上 传
        </el-button>
        <el-button
          size="small"
          icon="el-icon-edit"
          type="text"
          v-if="scope.row.id!=null"
          @click="downloadFile(scope.row)"
        >下 载
        </el-button>
        <el-button
          size="small"
          icon="el-icon-delete"
          type="text"
          v-if="scope.row.id!=null"
          @click="removeFile(scope.row)"
        >删 除
        </el-button>
      </template>
    </avue-crud>


    <el-upload
      ref="upload"
      class="upload-demo"
      :show-file-list="false"
      action="/api/sinoma-resource/oss/endpoint/put-file-attach"
      multiple
      :headers="uploadHeader"
      :on-success="uploadSuccess"
      :limit="3">
    </el-upload>
  </div>
</template>
<script>
import request from '@/router/axios';
import {Base64} from "js-base64";
import {getToken} from '@/util/auth';
import website from '@/config/website';
import {getNoPageList, updateRows} from "@/api/resource/attach";
import GridLayout from "@/views/components/layout/grid-layout";

export default {
  components: {
     GridLayout
  },
  props: {
    queryParams: {
      type: Object
    },
    dicDataBoolean: {
      type: Boolean,
      default: false
    },
    attachType: {
      type: String,
      default: ""
    },
    attachCode: {
      type: String,
      default: ""
    },
    attachId: {
      type: String,
      default: ""
    },

  },
  data() {
    return {
      grid: null,
      clientHeight: "",
      selectionList: [],
      tableData: [],
      option: {
        calcHeight: 1,
        height: 'auto',
        tip: false,
        searchShow: false,
        card: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        removeBtn: false,
        refreshBtn: false,
        searchBtn: false,
        columnBtn: false,
        searchShowBtn: false,
        emptyBtn: false,
        delBtn: false,
        editBtn: false,
        selection: true,
        filterBtn: false,
        header: true,
        dialogClickModal: false,
        column: [

          {
            label: "附件类型",
            prop: "attachCode",
            value: this.dicType,
            hide: true
          },
          {
            label: "附件分类",
            type: "select",
            dicUrl: "/api/sinoma-system/dict-biz/dictionaryByCode?attachType=" + this.attachType + "&attachCode=" + this.attachCode,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            prop: "attachType",
            hide: this.dicDataBoolean ? false : true,
            cell: true
          },
          {
            label: "附件名称",
            prop: "originalName",
            hide: false
          },
          {
            label: "附件大小（KB）",
            prop: "attachSize",
            hide: false
          }
        ]
      },
      loading: false,
      pageType: "",
      isShow: true,
      currentRow: null,
      currentBtn: null,
      uploadHeader: {},
      gridHeadBtn: [{
        label: this.$t(`cip.cmn.btn.addBtn`),
        emit: "head-add",
        type: "button",
        btnOptType: "add",
        icon: ""
      }, {
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-remove",
        type: "button",
        btnOptType: "remove",
        icon: ""
      }],
    };
  },

  created() {

    if (this.$route.query.hasOwnProperty("pageType")) {
      this.pageType = this.$route.query.pageType;
    } else if (this.$route.query.hasOwnProperty("type")) {
      this.pageType = this.$route.query.type;
    }

    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight
        this.clientHeight = window.screenHeight;
      })()
    };
  },
  mounted() {


  },
  watch: {
    clientHeight: {
      handler(newVal, oldVal) {
        this.initEyesClient();
      }
    }
  },
  methods: {
    getGrid() {
      return this.$refs.grid
    },
    getData(url, methodType) {
      const result = [];
      return request({
        url: url,
        method: methodType
      })
    },
    addRow() {
      this.tableData.push({
        originalName: "",
        attachSize: "",
        attachType: this.attachType,
        attachId: this.attachId,
        $cellEdit: true
      })
    },
    uploadClick(row) {
      this.uploadHeader['Authorization'] = `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`;
      this.uploadHeader[website.tokenHeader] = 'bearer ' + getToken()
      this.btnUploadClick(row)
    },
    btnUploadClick(row) {
      this.$refs['upload'].$children[0].$refs.input.click()
      this.currentRow = row;
    },
    uploadSuccess(response, file, fileList) {
      //this.$emit(this.currentBtn.emit, this.currentRow, response)
      const fileData = response.data;
      this.currentRow["id"] = fileData.id;
      this.currentRow["originalName"] = fileData.originalName;
      this.currentRow["attachSize"] = fileData.attachSize;
      this.currentRow["attachType"] = fileData.attachType;
      this.currentRow["attachCode"] = fileData.attachCode;
    },
    updateRow(attachId,callback) {
      console.log(this.$refs.grid);
      const dataset = this.$refs.grid.data;
      console.log(dataset);
      const rows = dataset.filter((d) => {
        return d.attachId == "" || d.attachId == null;
      })
      rows.forEach(function(row){
        row.attachId = attachId;
      })
      updateRows(rows).then((res)=>{
        callback(res);
      })
    },
    downloadFile(row, index) {
    },
    removeFile(row, index) {
    },
    onLoad(page, params = {}) {
      if (this.queryParams != null && this.queryParams != {}) {
        getNoPageList(Object.assign(params, this.queryParams)).then(res => {
          this.loading = false;
          this.tableData = res.data.data;
        });
      }
    },
    initEyesClient() {
      this.isShow = false
      this.$nextTick(() => {
        this.isShow = true
      })
    }
  },

};
</script>

<style lang="scss" scoped>
::v-deep .el-button + .el-button {
      margin-left: 10px;
}
.el-card__body {
  padding: 0px !important;
}

/* .avue-crud__pagination {
  .el-card__body {
    margin: 0 !important;
    padding: 1px 25px 0px 25px !important;
    width: 100% !important;
    height: auto !important;
    background: #f3f3f3 !important;
    box-sizing: border-box;
    .el-pagination {
      width: 100%;
      line-height: 32px;
      .el-pagination__total,
      .el-pagination__sizes {
        float: left;
      }
    }
  }
} */
.avue-form {
  height: 44px;
}

.avue-crud__pagination {
  background-color: #ffffff !important;
}

.el-link {
  color: #1b1b1b;
  text-decoration: underline;
  text-decoration-color: #1b1b1b;
}

.el-link:hover {
  color: #6c757d;
  text-decoration: none;
}
</style>


<style scoped>
</style>
