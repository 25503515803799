var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c(
        "el-container",
        [
          _c(
            "div",
            { staticClass: "content", staticStyle: { position: "relative" } },
            [
              _c("CommonTreeNew", {
                ref: "commonTree",
                attrs: {
                  treeTitle: "组织架构",
                  defaultProps: _vm.Props,
                  showCheckbox: false,
                  urlParmas: {
                    tenantId: "",
                    deptCategory: "",
                    parentId: _vm.userInfo.dept_id,
                  },
                  "node-key": "id",
                  isShowdig: false,
                  isZoom: true,
                  show: _vm.show,
                },
                on: {
                  getNodeClick: _vm.treeNodeClick,
                  showChange: _vm.showChange,
                },
              }),
            ],
            1
          ),
          _c(
            "el-container",
            [
              _c(
                "el-main",
                [
                  _c("grid-head-layout", {
                    ref: "gridHeadLayout",
                    attrs: {
                      "grid-head-btn": _vm.gridHeadBtn,
                      "search-columns": _vm.leftSearchColumns,
                      deviceProtocol: _vm.deviceProtocol,
                    },
                    on: {
                      "grid-head-search": _vm.leftGridHeadSearch,
                      "grid-head-empty": _vm.leftGridHeadEmpty,
                    },
                  }),
                  _c("grid-layout", {
                    ref: "leftGridLayOut",
                    staticClass: "departTable",
                    attrs: {
                      "grid-row-btn": _vm.gridRowBtn,
                      "table-options": _vm.tableOptions,
                      "table-data": _vm.leftTableData,
                      "table-loading": _vm.leftTableLoading,
                      "data-total": _vm.leftPage.total,
                      page: _vm.leftPage,
                    },
                    on: {
                      "page-current-change": _vm.leftOnLoad,
                      "page-size-change": _vm.leftOnLoad,
                      "page-refresh-change": _vm.leftOnLoad,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }